import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    ParaPoint: '⟶',
    ParaOneLeft:
      'Over the years we have been talking about technology, automation, data, process, and organizational change. But the past years have compelled us to switch to Digital transformation in pharma and pave the way for smoother and smarter workflows. We must swiftly bridge the gap with leapfrog technology in the biopharma and life sciences domain. The current trends in the biopharma industry reveal that technologies like Artificial Intelligence, Automation, and Cloud, if introduced correctly, will lead to quicker and cost-effective',
    ParaOneAnchor: 'procurement and Supply Chain Management (SCM).',
    ParaOneRight:
      'The pharma, and life sciences industry has been in the spotlight especially due to the pandemic. Now is the time to explore, invest and reap the benefits by strategically aligning digital trends and revolutionizing supply chain automation, predictive supply chain analytics and, positioning our business for business growth, by improving process efficiency of manufacturing.',
    ShiftUrlParaOne: 'https://raindrop.com/resources/TheFutureofSupplyChainandProcurement',
    ParaTwo:
      'But, before we explore the importance of digitization in the biotech pharmaceutical industry, it’s crucial to get answers to some common questions.',
    HeadingOne: 'What is Digital Transformation in pharma?',
    ParaThree:
      'In simple terms, we can say that digital transformation is the process of establishing or upgrading a new and advanced way to carry out routine business activities. The strategies and vision to drive digital transformation revolve around the smooth adaptation of trending technology advancements to provide better customer experiences and lead to intelligent decision-making, while ramping-up market requirements.',
    HeadingTwo: 'What are the main areas of Digital Transformation in Biotech?',
    ParaFour:
      'The majority of companies in the biotech, healthcare, pharma, life sciences, and biopharma industry recognize that digital transformation is the key to leading the rapid dynamics in the ecosystem.',
    ParaFiveLeft: 'According to a recent report by',
    ParaFiveAnchor: 'KPMG,',
    ParaFiveRight: '-',
    ShiftUrlParaFive: 'https://assets.kpmg/content/dam/kpmg/ch/pdf/digitalization-in-life-sciences.pdf',
    HeadingThree:
      '“85% of respondents see tech companies as the driving force of digitalization in their industry, and 63% see these companies as possible cooperation partners within the next year.”',
    ParaSix:
      'The biotech and pharma companies must adapt to the digital culture and embrace innovation as a part of their processes, to move on rapidly. Applying new initiatives, artificial intelligence, and automation in the supply chain eliminates real-time monitoring and results in error-free production.',
    HeadingFour: 'Recent Trends in Biotechnology',
    ParaSeven:
      'There is great potential to transform the industry in new and exciting ways. As shared below, technology adoption with digitalization creates a significant opportunity for companies to become more agile and competitive globally.',
    ParaEight: 'A. Enterprise Infrastructure and Platform',
    ParaNine: 'B. Analytics using AI-ML',
    ParaTen: 'C. Data Connectivity',
    ParaEleven: 'D. Cloud Computing',
    ParaTwelve: 'E. Auto-validation',
    ParaThirteen: 'F. Data Visualization, AR-VR',
    // ParaFourteen: 'G. Data Visualization, AR-VR',
    ParaFifteen: 'G. Mobility devices, Chatbots, Robotics',
    ParaSixteen: 'H. Cybersecurity',
    ImagePathOne: 'https://storage.googleapis.com/raindroppublic/website_data/BiotechImage.jpg',
    HeadingFive: 'Identifying Future Trends in Biotechnology',
    ParaSeventeenLeft: 'According to',
    ParaSeventeenAnchorOne: ' Forbes,',
    ParaSeventeenBetweenOne: '“The value of the global biotech market is',
    ParaSeventeenAnchorTwo: ' forecast to pass $100 billion',
    ParaSeventeenRight:
      'in 2022.” Let’s check out the key trends that will drive breakthroughs and innovations in the biopharma, and life sciences industries.',
    AnchorShiftOne:
      'https://www.forbes.com/sites/bernardmarr/2021/12/08/the-5-biggest-biotech-trends-in-2022/?sh=5af2d0db380f',
    AnchorShiftTwo:
      'https://www.mynewsdesk.com/se/newswire/pressreleases/biotechnology-market-size-share-global-industry-analysis-and-forecast-2021-to-2030-3118893',
    ParaEighteenLeft:
      'Today, everyone worldwide is looking for faster pharmaceutical testing and approval of medicines, especially following the development and approval of Covid-19 vaccinations. Data and research play an important role here. The RTOR, or',
    ParaEighteenAnchorOne: ' Real-Time Oncology Review,',
    ParaEighteenBetweenOne: '“shared by the US Food and Drug Administration',
    ParaEighteenAnchorTwo: ' (FDA)',
    ParaEighteenRight:
      'is a testimony of the fact that the inclusion of biotech pharma data has the potential to accurately predict testing outcomes of trials of novel cancer treatments. We are surely going to witness quicker developments of effective treatments, and drugs in the coming years, with the inclusion of the right forms of digital technologies.',
    AnchorShiftThree: 'https://www.fda.gov/about-fda/oncology-center-excellence/real-time-oncology-review',
    AnchorShiftFour: 'https://www.fda.gov/',
    HeadingSix: '1. Predictive and Autonomous SCM',
    ParaNineteen:
      'An autonomous supply chain aids in achieving inventory accuracy with minimal human intervention. Supply Chain Management or SCM is one of the most challenging domains and industry leaders emphasize technological advancements like using AI in getting 100% accurate projection and forecasting the customer demand. Optimizing research and development on the cloud empowers businesses with innovation, creating value for customers and reducing time to market. Integration of AI along with cloud-based machine learning has helped in cost-reduction, and improved asset utilization, leading to increased revenue.',
    HeadingSeven: '2. Yield Optimization & Sourcing',
    ParaTwenty:
      'Manufacturers in the biopharma industry need to connect equipment, devices, lines, and employees across the supply chain for smarter and rapid operations. With increasing scrutiny for regulatory compliances and standards, it is essential to ensure continuous processes with quality outputs through digital transformation. There is limited or no margin left for errors or iterations when there is a huge demand to bring personalized medicines and drugs to market, in shorter time frames and different batch sizes. Predictive yield optimization backed up by AI-ML will swiftly manage in creating more complex portfolios with segmentation and offering transparency at all stages.',
    HeadingEight: '3. Real-time Tracking and Tracing',
    ParaTwentyOne:
      'The regulatory and compliance challenges in the biopharma sector call for improved track-and-trace product movement and solutions. It is an important global driver for investors and leaders in the industry. Digital transformation is a strategic imperative to gain a competitive edge while offering real-time supply chain visibility. To meet customer',
    HeadingNine: '4. Organize to Scale',
    ParaTwentyTwo:
      'With large-scale disruption in the healthcare industry, there is a pressing need for full-scale platform transformation. By establishing repeatable deployment modules and operating models, processes can be streamlined allowing swift scalability.',
    HeadingTen: '5. Working on Key Performance Indicators, KPIs',
    ParaTwentyThree:
      'It is vital to check numbers, measure them and improve. Without monitoring and measuring the KPIs, it is difficult to measure the success of an organization and work to overcome failures. Some of the common performance indicators trusted by biotech companies help in the evaluation of the following information:',
    ParaTwentyFour: 'A.   Vendors',
    ParaTwentyFive: 'B.   Sourcing processes',
    ParaTwentySix: 'C.   RFQ Documentation',
    ParaTwentySeven: 'D.  Contract Management',
    ParaTwentyEight: 'E.   Reverse Auction Results',
    ParaTwentyNine: 'F.   Purchase orders',
    ParaThirty: 'G.  Supplier delivery times',
    ParaThirtyOne: 'H.  Source to payable',
    ParaThirtyTwo: 'I.    Source to Contract',
    ImagePathTwo: 'https://storage.googleapis.com/raindroppublic/website_data/Biotech-Image.jpg',
    ParaThirtyThreeLeft: 'Entrepreneurs in the biopharma industry must use the',
    ParaThirtyThreeAnchor: ' best SaaS platform',
    ParaThirtyThreeRight:
      'to measure the above metrics to evaluate their procurement efforts and improve further. It helps in checking key deliverables and supplier engagement while ensuring cost savings.',
    ShiftUrlParaThirtyThree: 'https://raindrop.com/',
    HeadingEleven: 'To Conclude:',
    ParaThirtyFour:
      'To be future-ready, it is time to take steps to begin your digital transformation journey. Raindrop’s source-to-contract [SM1] solution enables continuous improvement in manufacturing and supply chain through monitoring and control systems. Smart connected operations are strategic to improve manufacturing performance turning from reactive to predictive and prescriptive.',
  },
]
