import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import AcceleratingDigitalPendingData from '../Data/AcceleratingDigitalPendingData'
import { ReUsePtagwithAnchorTag } from './ReUsePtagwithAnchorTag'
// import { ReUsePtagwithThreeAnchorTag } from './ReUsePtagwithThreeAnchorTag'
import { ReUsePtagwithTwoAnchorTag } from './ReUsePtagwithTwoAnchorTag'

const AppAcceleratingDigitalRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .RetailIndustryContentSectionEmail {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .RetailIndustryHeadingEmail {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .RetailIndustryLeadParaStyleOne {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .RetailIndustryLeadParaStyle {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .achorPara {
    color: #34a1d5;
    font-size: 20px;
    font-weight: 700;
    padding-right: 8px;
    padding-left: 8px;
    text-decoration: none;
  }
  .achorPara:hover {
    color: #047cc2;
    text-decoration: underline;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .RetailIndustryParaSubPointStyle {
    color: #047cc2;
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    font-size: 22px;
    font-weight: bold;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .ItalyPara {
    display: flex;
    color: #071741;
    max-width: 1150px;
    font-size: 1.2rem;
    line-height: 1.4;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
      font-size: 16px;
    }
  }
  .BlogsimageOne {
    width: 90%;
    display: flex;
    padding-left: 64px;
    /* float: right; */
    @media (max-width: 1090px) {
      float: unset;
      width: 100%;
    }
  }
  .RetailLeadParaStyleOne {
    padding: 0px 15px 0px 45px;
  }
  .RetailIndustryHeadingFiveEmail {
    padding: 15px 15px 15px 45px;
  }
  .ParaStyleMultiple {
    padding: 0px 15px 0px 45px;
  }
`

export const AppAcceleratingDigitalRest = () => {
  return (
    <AppAcceleratingDigitalRestWapper>
      <div>
        {AcceleratingDigitalPendingData.map((data, index) => {
          return (
            <div className="MainDigitizationNeedsSection" key={index}>
              <div className="RetailIndustryContentSectionEmail">
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaOneLeft}
                  anchorPara={data.ParaOneAnchor}
                  ParaRight={data.ParaOneRight}
                  href={data.ShiftUrlParaOne}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RetailLeadParaStyleOne"
                />
                <ReUsePtag para={data.ParaTwo} paraStyle="RetailIndustryLeadParaStyleOne" />
                <ReUseHTwotag Heading={data.HeadingOne} HeadingStyle="RetailIndustryHeadingEmail" />
                <ReUsePtag para={data.ParaThree} paraStyle="RetailIndustryLeadParaStyleOne" />
                <ReUseHTwotag Heading={data.HeadingTwo} HeadingStyle="RetailIndustryHeadingEmail" />
                <ReUsePtag para={data.ParaFour} paraStyle="RetailIndustryLeadParaStyleOne" />
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaFiveLeft}
                  anchorPara={data.ParaFiveAnchor}
                  ParaRight={data.ParaFiveRight}
                  href={data.ShiftUrlParaFive}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RetailLeadParaStyleOne"
                />
                <ReUseHTwotag Heading={data.HeadingThree} HeadingStyle="RetailIndustryHeadingEmail" />
                <ReUsePtag para={data.ParaSix} paraStyle="RetailIndustryLeadParaStyleOne" />
                <ReUseHTwotag Heading={data.HeadingFour} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtag para={data.ParaSeven} paraStyle="RetailIndustryLeadParaStyleOne" />
                <ReUsePtag para={data.ParaEight} paraStyle="RetailIndustryLeadParaStyleOne" />
                <ReUsePtag para={data.ParaNine} paraStyle="RetailIndustryLeadParaStyleOne" />
                <ReUsePtag para={data.ParaTen} paraStyle="RetailIndustryLeadParaStyle" />
                <ReUsePtag para={data.ParaTwelve} paraStyle="RetailIndustryLeadParaStyle" />
                <ReUsePtag para={data.ParaThirteen} paraStyle="RetailIndustryLeadParaStyle" />
                {/* <ReUsePtag para={data.ParaFourteen} paraStyle="RetailIndustryLeadParaStyle" /> */}
                <ReUsePtag para={data.ParaFifteen} paraStyle="RetailIndustryLeadParaStyle" />
                <ReUsePtag para={data.ParaSixteen} paraStyle="RetailIndustryLeadParaStyle" />
                <img src={data.ImagePathOne} className="BlogsimageOne" />
                <ReUseHTwotag Heading={data.HeadingFive} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtagwithTwoAnchorTag
                  ParaLeft={data.ParaSeventeenLeft}
                  anchorParaOne={data.ParaSeventeenAnchorOne}
                  ParaBetween={data.ParaSeventeenBetweenOne}
                  anchorParaTwo={data.ParaSeventeenAnchorTwo}
                  ParaRight={data.ParaSeventeenRight}
                  hrefOne={data.AnchorShiftOne}
                  hrefTwo={data.AnchorShiftTwo}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RetailIndustryLeadParaStyle"
                />
                <ReUsePtagwithTwoAnchorTag
                  ParaLeft={data.ParaEighteenLeft}
                  anchorParaOne={data.ParaEighteenAnchorOne}
                  anchorParaTwo={data.ParaEighteenAnchorTwo}
                  ParaBetween={data.ParaEighteenBetweenOne}
                  ParaRight={data.ParaEighteenRight}
                  hrefOne={data.AnchorShiftThree}
                  hrefTwo={data.AnchorShiftFour}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RetailIndustryLeadParaStyle"
                />
                <ReUseHTwotag Heading={data.HeadingSix} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtag para={data.ParaNineteen} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingSeven} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtag para={data.ParaTwenty} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingEight} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtag para={data.ParaTwentyOne} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingNine} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtag para={data.ParaTwentyTwo} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingTen} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtag para={data.ParaTwentyThree} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTwentyFour} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTwentyFive} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTwentySix} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTwentySeven} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTwentyEight} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTwentyNine} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirty} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtyOne} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirtyTwo} paraStyle="ParaStyleMultiple" />
                <img src={data.ImagePathTwo} className="BlogsimageOne" />
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaThirtyThreeLeft}
                  anchorPara={data.ParaThirtyThreeAnchor}
                  ParaRight={data.ParaThirtyThreeRight}
                  href={data.ShiftUrlParaThirtyThree}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RetailLeadParaStyleOne"
                />
                <ReUseHTwotag Heading={data.HeadingEleven} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtag para={data.ParaThirtyFour} paraStyle="ParaStyleMultiple" />
              </div>
            </div>
          )
        })}
      </div>
    </AppAcceleratingDigitalRestWapper>
  )
}
